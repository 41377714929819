<template>
  <div id="merchant-page">
    <h2 class="mb-4">{{$t('Merchants')}}</h2>
    
    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      advancedSearch
      :searchCriteria="searchCriteria"
      @selectedCriteria="selectedCriteria = $event"
      :data="merchants"
      :tableLoader="tableLoader"
    >
      <template slot="header">
        <table-filter :filters="filters" :statusFilters="statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event"/>
      </template>

      <template slot="thead">
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Account Number')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Email')}}</shipblu-th>
        <shipblu-th>{{$t('CoD Balance')}}</shipblu-th>
        <shipblu-th>{{$t('Wallet Balance')}}</shipblu-th>
        <shipblu-th>{{$t('Service fees & Refunds due')}}</shipblu-th>
        <shipblu-th>
          <div class="flex items-center gap-2">
            <p class="whitespace-no-wrap">{{$t('Net cash balance')}}</p>
            <vx-tooltip position="top" color="dark" :text="`${$t('Net cassh balance')} = ${$t('CoD balance')} - ${$t('Service fees & Refunds due')}`">
              <span class="material-symbols-outlined flex text-base cursor-pointer">info</span>
            </vx-tooltip>
          </div>
        </shipblu-th>
        <shipblu-th>{{$t('Quickbooks last updated')}}</shipblu-th>
        <shipblu-th>{{$t('Store Phone')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-merchant-view`, params: { merchantID: data[indextr].id, tab: 'info' }}">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ (data[indextr].name !== data[indextr].display_name && data[indextr].display_name !== null) ? data[indextr].name + ' - ' + data[indextr].display_name : data[indextr].name}}
              </p>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].id" class="order-3">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Account Number')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].id }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-3" :data="data[indextr].store_url">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Merchant Email')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].store_email }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].codBalance">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('CoD Balance')}}</p>
            <p class="sm:text-base text-sm">
              {{ Number(data[indextr].quickbooks_cod_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].walletBalance">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Wallet Balance')}}</p>
            <p class="sm:text-base text-sm">
              {{ Number(data[indextr].quickbooks_wallet_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].fees">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Service fees & Refunds due')}}</p>
            <p class="sm:text-base text-sm">
              {{ Number(data[indextr].quickbooks_customer_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].netCash">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Net cash balance')}}</p>
            <p class="sm:text-base text-sm">
              {{ Number(data[indextr].quickbooks_cod_balance - data[indextr].quickbooks_customer_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].fees">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Quickbooks last updated')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].quickbooks_last_updated ? new Date(data[indextr].quickbooks_last_updated).toLocaleDateString('fr-CA') + ' ' + new Date(data[indextr].quickbooks_last_updated).toLocaleTimeString('en-GB') : '' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-9" :data="data[indextr].store_phone">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Store Phone')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].store_phone }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-10" :data="data[indextr].location">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Location')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].address.zone ? $t(data[indextr].address.zone.name) : 'N/A' }} / {{ data[indextr].address.zone ? $t(data[indextr].address.zone.city.name) : 'N/A' }}
            </p>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOpwtions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'
import TableFilter from '../../views/merchant/components/TableFilter.vue'

export default {
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      merchantURL: 'api/v1/merchants/',
      selected: [],
      merchants: [],
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchInProgress: false,
      tableLoader: false,
      searchedValue: ' ',
      statusFilters: [
        {
          name: 'all',
          value: 'all'
        },
        {
          name: 'Negative COD Balance',
          data: 'cod_balance__lt=0',
          value: 'negative_cod_balance'
        },
        {
          name: 'Negative Wallet Balance',
          data: 'wallet_balance__lt=0',
          value: 'negative_wallet_balance'
        },
        {
          name: 'Negative Net Cash Balance',
          data: 'net_cash_balance=negative',
          value: 'negative_net_cash_balance'
        }
      ],
      filtersDic: {'all': '', 'negative_wallet_balance': 'wallet_balance__lt=0', 'negative_cod_balance': 'cod_balance__lt=0', 'negative_net_cash_balance': 'net_cash_balance=negative'},
      filters: [],
      filterValue: '',
      selectedCriteria: {},
      searchCriteria: [
        {
          label: 'Merchant Name',
          value: 'name'
        },
        {
          label: 'Merchant ID',
          value: 'id'
        },
        {
          label: 'Email',
          value: 'store_email'
        },
        {
          label: 'Store Phone',
          value: 'store_phone'
        }
      ]
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: 'finance-accounts-merchants',
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadMerchants()
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadMerchants()
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadMerchantsSearch)
    },
    loadMerchantsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadMerchants()
    },
    loadMerchants () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}&${this.selectedCriteria.value ? this.selectedCriteria.value : this.searchCriteria[0].value}=${this.searchVal}${this.filtersDic[this.filters] ? `&${this.filtersDic[this.filters]}` : ''}`
      sendRequest(true, false, this, `${this.merchantURL}${query}`, 'get', null, true,
        (response) => {
          this.merchants = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }, (error) => {
          this.merchants = []
          this.totalRows = 0
          this.searchedValue = this.searchVal
          common.notifyResponseError(this, error)
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    viewMerchant (data) {
      this.$router.push(
        {
          name: `${this.$store.state.AppActiveUser.userRole}-merchant-view`,
          params: {
            merchantID: data.id,
            tab: 'info'
          }
        }
      ).catch(() => {})
    },
    changeFilter () {
      this.filters = []
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      this.currentPage = 1
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadMerchants()
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination,
    TableFilter
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.offset = (this.currentPage - 1) * this.maximumItems
  }
}
</script>

<style lang="scss">
.con-pagination-table{
  display: none;
}
</style>
